<!--
*  TTTech nerve-management-system
*  Copyright(c) 2023. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <div @mouseover="isTooltip($event)">
    <v-tooltip v-if="tooltip" bottom>
      <template #activator="{ on }">
        <div
          :class="{
            tableItemText: (linkKey === 'target' && !isTargetAllowedAsLink) || !isLinkAllowed || disableLink,
            tableItemTextLink:
              (linkKey === 'target' && isTargetAllowedAsLink) ||
              (linkKey !== 'target' && isLinkAllowed && !disableLink),
          }"
          v-on="on"
        >
          <div
            v-if="linkKey === 'target' && isTargetAllowedAsLink"
            :id="`iiotTableContentAsLink_${linkKey}_${index}`"
            class="tableLinkDisabled"
          >
            <span class="tableLink cursor-pointer" @click="linkClicked">
              {{ workloadName }}
            </span>
            <span class="tableLinkDisabled"> :{{ workloadPort }} </span>
          </div>
          <span
            v-if="linkKey !== 'target' && isLinkAllowed && !disableLink"
            :id="`iiotTableContentAsLink_${linkKey}_${index}`"
            class="tableLink cursor-pointer"
            @click="linkClicked"
          >
            {{ tableItem[linkKey] }}
          </span>
          <span
            v-if="
              (linkKey !== 'target' && (!isLinkAllowed || disableLink)) ||
              (linkKey === 'target' && !isTargetAllowedAsLink)
            "
            :id="`iiotTableContentAsLink_${linkKey}_${index}`"
            class="tableLinkDisabled"
          >
            {{ tableItem[linkKey] }}
          </span>
        </div>
      </template>
      <span>{{ tableItem[linkKey] }}</span>
    </v-tooltip>
    <div
      v-else
      class="pa-0 ma-0"
      :class="{
        tableItemText: (linkKey === 'target' && !isTargetAllowedAsLink) || !isLinkAllowed || disableLink,
        tableItemTextLink:
          (linkKey === 'target' && isTargetAllowedAsLink) || (linkKey !== 'target' && isLinkAllowed && !disableLink),
      }"
    >
      <div
        v-if="linkKey === 'target' && isTargetAllowedAsLink"
        :id="`iiotTableContentAsLink_${linkKey}_${index}`"
        class="tableLinkDisabled"
      >
        <span class="tableLink cursor-pointer" @click="linkClicked">
          {{ workloadName }}
        </span>
        <span class="tableLinkDisabled"> :{{ workloadPort }} </span>
      </div>
      <span
        v-if="linkKey !== 'target' && isLinkAllowed && !disableLink"
        :id="`iiotTableContentAsLink_${linkKey}_${index}`"
        class="tableLink cursor-pointer"
        @click="linkClicked"
      >
        {{ tableItem[linkKey] }}
      </span>
      <span
        v-if="
          (linkKey !== 'target' && (!isLinkAllowed || disableLink)) || (linkKey === 'target' && !isTargetAllowedAsLink)
        "
        :id="`iiotTableContentAsLink_${linkKey}_${index}`"
        class="tableLinkDisabled"
      >
        {{ tableItem[linkKey] }}
      </span>
    </div>
  </div>
</template>

<script>
import ComposeWorkloadApiService from '@/services/api/compose-workload.api-service';
import { WorkloadsApiService, NodesApiService } from '@/services/api';
import UserApiService from '@/services/api/users.api-service';
import NodeTreeHelper from '@/store/helpers/node-tree.helper';

export default {
  props: {
    tableItem: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 0,
    },
    linkKey: {
      type: String,
      default: '',
    },
    isLinkAllowed: {
      type: Boolean,
      default: false,
    },
    queryParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dockerRcTab: 5,
      vmRcTab: 4,
      composeRcTab: 2,
      codesysRcTab: 1,
      tooltip: false,
      disableLink: this.linkKey === 'device',
    };
  },
  computed: {
    nodes() {
      return this.$store.getters['node-tree/getNodes'];
    },
    isTargetAllowedAsLink() {
      return !!(this.tableItem.workloadId && this.tableItem.versionId);
    },
    workloadName() {
      return this.linkKey === 'target' ? this.tableItem[this.linkKey].split(':')[0] : '';
    },
    workloadPort() {
      return this.linkKey === 'target' ? this.tableItem[this.linkKey].split(':')[1] : '';
    },
  },
  watch: {
    nodes(value) {
      if (this.linkKey === 'device') {
        this.checkIfNodeExists(value);
      }
    },
  },
  async mounted() {
    if (this.linkKey === 'device' && this.nodes && this.nodes[0]) {
      this.checkIfNodeExists(this.nodes);
    }
  },
  methods: {
    async linkClicked() {
      // eslint-disable-next-line default-case
      switch (this.linkKey) {
        case 'connection':
          // If connection is created on workload reroute to RC tab of workload version view page
          if (this.tableItem.workloadId && this.tableItem.versionId) {
            await this.handleWorkloadRerouting();
            return;
          }
          // If connection is created on node reroute to Node view page
          await this.handleNodeRerouting();
          break;
        case 'node':
        case 'device': {
          try {
            // Reroute user on Node overview tab in node tree
            await this.handleNodeTreeRerouting();
          } catch (e) {
            this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
              text: 'errorMessages.nerve_node_012',
              color: 'red',
              showClose: true,
            });
            this.disableLink = true;
          }
          break;
        }
        case 'user':
          try {
            await UserApiService.getUser(this.tableItem.userId);
            this.$router.push({ name: 'Add Edit User', params: { id: this.tableItem.userId } });
          } catch {
            this.disableLink = true;
          }
          break;
        case 'target':
          await this.handleWorkloadRerouting('basic');
          break;
      }
    },
    async handleWorkloadRerouting(tab) {
      let wl = {};
      try {
        wl = await ComposeWorkloadApiService.getComposeWorkload({ id: this.tableItem.workloadId });
        if (wl.type === 'docker-compose') {
          await ComposeWorkloadApiService.fetchComposeWorkloadVersionById({
            wlId: this.tableItem.workloadId,
            verId: this.tableItem.versionId,
          });
        } else {
          const wlWithVersions = await WorkloadsApiService.getWorkloadVersions({
            id: this.tableItem.workloadId,
          });
          const version =
            wlWithVersions && wlWithVersions.versions
              ? wlWithVersions.versions.find((v) => v._id === this.tableItem.versionId)
              : {};
          if (!version) {
            this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
              text: 'errorMessages.000703',
              color: 'red',
              showClose: true,
            });
            await this.$store.dispatch('active-remote-connections/fetch', this.queryParams);
            return;
          }
        }
      } catch {
        await this.$store.dispatch('active-remote-connections/fetch', this.queryParams);
        return;
      }
      await this.$store.dispatch('workloads/set_selected_deployed_workload_version_timestamp', {
        dateString: this.tableItem.created,
        isActiveRc: true,
      });
      if (tab === 'basic') {
        await this.$router.push({
          name: 'Add edit workload version',
          params: {
            workloadId: this.tableItem.workloadId,
            versionId: this.tableItem.versionId,
            type: wl.type,
          },
          query: {
            tab: 0,
          },
        });
        return;
      }
      await this.rerouteToRcTabInVersionView(wl.type);
    },
    async rerouteToRcTabInVersionView(type) {
      let tab = 0;
      switch (type) {
        case 'docker':
          tab = this.dockerRcTab;
          break;
        case 'vm':
          tab = this.vmRcTab;
          break;
        case 'docker-compose':
          tab = this.composeRcTab;
          break;
        default:
          tab = this.codesysRcTab;
      }
      await this.$router.push({
        name: 'Add edit workload version',
        params: {
          workloadId: this.tableItem.workloadId,
          versionId: this.tableItem.versionId,
          type,
        },
        query: {
          tab,
        },
      });
    },
    async handleNodeTreeRerouting() {
      const searchTerm = this.tableItem.serialNumber || this.tableItem.device;
      await this.$store.dispatch('node-tree/get_full_tree');
      const node = await NodeTreeHelper.searchNodesRecursively(this.nodes, 'serialNumber', searchTerm);
      if (!node || !node.length) {
        throw Error;
      }
      await this.$store.dispatch('node-tree/handle_rerouting_to_node_tree', {
        searchParam: 'serialNumber',
        searchTerm,
        treeNode: { data: node },
        workloadControlPermission: this.canAccess('UI_WORKLOAD_CONTROL:LIST'),
      });
    },
    // tooltip will be shown only in the case when text is too big and when contains 3 dots
    isTooltip(event) {
      if (this.linkKey === 'target' && this.isTargetAllowedAsLink) {
        const target = document.getElementById(`iiotTableContentAsLink_target_${this.index}`);
        this.tooltip =
          target.offsetWidth > event.target.offsetParent.offsetWidth - 33 && target.offsetWidth !== target.scrollWidth;
        return;
      }
      this.tooltip =
        event.target.offsetWidth > event.target.offsetParent.offsetWidth - 33 &&
        event.target.offsetWidth !== event.target.scrollWidth;
    },
    async handleNodeRerouting() {
      try {
        const foundNode = await NodesApiService.getNodeById(this.tableItem.nodeId);
        if (!foundNode) {
          throw Error;
        }
        this.$router.push({ name: 'Add edit node', params: { id: this.tableItem.nodeId } });
      } catch {
        this.disableLink = true;
      }
    },
    async checkIfNodeExists(value) {
      // If node with specific serialNumber is not in node-tree link will not be displayed
      const node = await NodeTreeHelper.searchNodesRecursively(value, 'serialNumber', this.tableItem.device);
      if (!node || !node.length) {
        this.disableLink = true;
        return;
      }
      this.disableLink = false;
    },
  },
};
</script>

<style lang="scss">
.tableLink {
  width: 100% !important;
  justify-content: start !important;
  text-transform: none !important;
  color: var(--v-primary-base);
}
.tableLink:hover {
  text-decoration: underline !important;
}
.tableLinkDisabled {
  width: 100% !important;
  justify-content: start !important;
  text-transform: none !important;
  color: #000000de !important;
  cursor: default !important;
}
.tableItemText {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.tableItemTextLink {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  color: var(--v-primary-base);
}
</style>
